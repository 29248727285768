import React, { Component } from 'react';
export default class Certificats extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="certificats">
      <div className="row">

        <div className="twelve columns collapsed">
            <h1 className='titleSection'>Certificats</h1>
          {
            resumeData.certificats && resumeData.certificats.map((item)=>{
              return(
                  <div className="item-wrap">
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <ul className={'linkPortfolio ' + item.class}>
                            {item.logo && 
                              <li key={item.logo}>
                                <a><i className={"fa " + item.logo}></i></a>
                              </li>
                            }
                            {item.imgPath && 
                              <div className='svgImg'>
                              <img src={item.imgPath} />
                              </div>

                            }
                          </ul>
                          <h5 className='title'>{item.name}</h5>
                          <h5 className='subTitle'>{item.company}</h5>
                          <p className='date'>{item.date}</p>
                        </div>
                      </div>
                  </div>
              )
            })
          }
        </div>
      </div>
  </section>
        );
  }
}