let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Hichem Mouloudji",
    "role": "Web developer (full stack)",
    "linkedinId":"https://www.linkedin.com/in/Hichem%20Mouloudji",
    "skypeid": "Your skypeid",
    "roleDescription": "(more than 3 years / +20 projects on gitlab)  and self-entrepreneur (Since 2021). I support my clients in the creation of tailor-made Web applications (ReactJs + Symfony) or in the process of digital transformation (Creation of showcase website /E-commerce ...)",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/Hichem%20Mouloudji",
          "className":"fa fa-linkedin"
        },
        {
          "name":"gitlab",
          "url":"https://gitlab.com/hichemmouloudji",
          "className":"fa fa-gitlab"
        },
        {
          "name":"website",
          "url":"https://divhart.com",
          "className":"fa fa-chrome"
        },
        {
          "name":"mail",
          "url":"mailto:?to=contact@divhart.com",
          "className":"fa fa-envelope"
        }
      ],
    "aboutme":"Having a creative spirit, I offer a good expertise in the development of Web applications, with the REACTJS framework and Symfony. My work does not stop at development, since I intervene in SEO referencing, in the integration of PWA for a mobile experience, integration of ERP/CRM software, increase website performances, logo creation and graphic charter and much more...    ",
    "address":"Algeria",
    "website":"https://divhart.com",
    "education":[
      {
        "UniversityName":"Claude Bernard University - Lyon 1",
        "specialization":"Master 2 in Information and Web Technologies",
        "MonthOfPassing":"From September 2020",
        "YearOfPassing":"to January 2021",
        "Achievements":""
      },
      {
        "UniversityName":"Abderrahmane Mira University",
        "specialization":"Master's degree in Computer Science, Software Engineering specialty",
        "MonthOfPassing":"From 2018",
        "YearOfPassing":" to 2019",
        "Achievements":""
      }
      ,
      {
        "UniversityName":"Meta | Coursera",
        "specialization":"Meta Front-End Developer - Professional Certiﬁcate ",
        "MonthOfPassing":"From 2022",
        "YearOfPassing":" to 2023",
        "Achievements":""
      }
    ],
    "work":[
      {
        "CompanyName":"DIVHART",
        "specialization":"Founder",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2022",
        "Achievements":"DIVHART is a company specialized in the ﬁeld of computer programming and consulting"
      },
      {
        "CompanyName":"Noorlink",
        "specialization":"Full stack web developer",
        "MonthOfLeaving":"From April 2020",
        "YearOfLeaving":" to October 2020",
        "Achievements":"Some Achievements"
      },
      {
        "CompanyName":"NEGOCE GAMME",
        "specialization":"Internship and training - Design and Development of Applications",
        "MonthOfLeaving":"From September 2019",
        "YearOfLeaving":" to October 2020",
        "Achievements":"Design and Development of WEB, Android and Fullstack Applications with the use of programming languages and recent Frameworks (Symfony, Angular, Ionic)"
      },
      {
        "CompanyName":"Abderrahmane Mira University",
        "specialization":"Presentation of the Technology Transfer Oﬃce platform",
        "MonthOfLeaving":"July 2019",
        "YearOfLeaving":" ",
        "Achievements":""
      },
      {
        "CompanyName":"Abderrahmane Mira University",
        "specialization":"Practical internship - Design of a web and Android application for the BuTT",
        "MonthOfLeaving":"From February 2019",
        "YearOfLeaving":" to April 2019",
        "Achievements":"Design of a web and Android Application for the Technology Transfer Oﬃce."
      },
      {
        "CompanyName":"Sommam Radio",
        "specialization":"Practical internship - Design of a dynamic website for Radio Soummam",
        "MonthOfLeaving":"2016",
        "YearOfLeaving":" 2017",
        "Achievements":""
      }
    ],
    "skillsDescription":"",
    "skills":[
      {
        "skillname":"ReactJs, Symfony, Bootstrap",
        "className":"skill2"

      },
      {
        "skillname":"HTML5, CSS3, JavaScript",
        "className":"skill1"
      },
      {
        "skillname":"PHP, TypeScript",
        "className":"skill3"

      },
      {
        "skillname":"API REST with Api platform",
        "className":"skill4"
      },
      {
        "skillname":"PWA",
        "className":"skill5"

      },
      {
        "skillname":"Wordpress - php code - E-commerce",
        "className":"skill6"

      },
      {
        "skillname":"Infographics and design with canva",
        "className":"skill7"

      },
      {
        "skillname":"Website Hosting",
        "className":"skill8"

      },
      {
        "skillname":"SEO",
        "className":"skill9"

      }
    ],
    "portfolio":[
      [
        // Development
        {
          "name":"Santé-IT",
          "description":"a platform created with the aim of facilitating interactions between patients and healthcare professionals in a world of digitalization.",
          "technologies": "ReactJs/Symfony",
          "link":"https://app.santeit.com/",
          "plateform":"Web/Mobile Application",
          "linkFile":"https://www.canva.com/design/DAFLn-ck9is/FQsyR_Lf4JFpEEsBtF5FKA/view?utm_content=DAFLn-ck9is&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel",
          "imgsurl":["images/portfolio/Santé-it/santé-it1.png", "images/portfolio/Santé-it/santé-it2.png"]
        },
        {
          "name":"Event management V2",
          "description":"Generation, sending by email and authentication of certificates, dynamic badges, integration of payment methods and more",
          "technologies": "ReactJs/Symfony/React native",
          "link":"",
          "plateform":"Web/Mobile Application",
          "linkFile":"",
          "imgsurl":["images/portfolio/Forum web app/1.png", "images/portfolio/Forum web app/2.png"]
        },
        {
          "name":"Event management V1",
          "description":"Online registration and receipt of badge in pdf format with a confirmation message, containing a unique qr code that allows check-in, management of guests, organizers, speakers .... and several other features",
          "technologies": "ReactJs/Symfony/React native",
          "link":"",
          "plateform":"Web/Mobile Application",
          "linkFile":"",
          "imgsurl":["images/portfolio/Forum web app/1.png", "images/portfolio/Forum web app/2.png"]
        },
        {
          "name":"EmailGenAi",
          "description":"EmailGenAI is a powerful script - a bot that operates as a cron job on a server to automatically respond to received emails at regular intervals. Using artificial intelligence, it generates draft email responses effortlessly and autonomously.",
          "technologies": "Php",
          "link":"",
          "plateform":"Script/Bot",
          "linkFile":"",
          "imgsurl":["images/portfolio/EMailGenAi/Présentation-emailGenAI.png"]
        },
        {
          "name":"Dbatel",
          "description":"donation platform for food and objects",
          "technologies": "ReactJs/Symfony",
          "link":"",
          "plateform":"Web Application",
          "linkFile":"",
          "imgsurl":["images/portfolio/Dbatel/Présentation dbatel.png"]
        },
        {
          "name":"Kranco",
          "description":"Website for a company selling spare parts in the field of public works and lifting equipment",
          "technologies": "Wordpress",
          "link":"",
          "plateform":"Website",
          "imgsurl":["images/portfolio/Kranco/Présentation Kranco.png"]
        },
        {
          "name":"Medeva",
          "description":"E-commerce business website in the medical equipment sector",
          "technologies": "Html/Css/Php/Wordpress",
          "link":"https://medeva-dz.com",
          "plateform":"Website",
          "imgsurl":["images/portfolio/Medeva/Présentation Medeva.png"]
        },
        {
          "name":"Chicarto",
          "description":"Online platform offering a variety of products with automated bulk import and AI-driven information generation through Python",
          "technologies": "Html/Css/Python/Php/Wordpress",
          "link":"https://chicarto.com",
          "plateform":"Website",
          "imgsurl":["images/portfolio/Chicarto/Présentation chicarto.png"]
        },
        {
          "name":"Artglo",
          "description":"A mini ERP including inventory management, suppliers, customers, invoicing, statistics, promotions ... and a website featuring products, orders with a payment method, and much more.",
          "technologies": "ReactJs/Symfony",
          "plateform":"Web Application / Website",
          "imgsurl":["images/portfolio/Artglo/Présentation mini erp.png"]
        },
        {
          "name":"Virtual hotel",
          "description":"Hotel management application",
          "technologies": "ReactJs/Symfony",
          "link":"",
          "plateform":"Web Application",
          "imgsurl":["images/portfolio/Virtual hotel/présentation_virtual_hotel1.png", "images/portfolio/Virtual hotel/présentation_virtual_hotel1.png"]
        },
        {
          "name":"Divqr",
          "description":"Qr code generator",
          "technologies": "ReactJs",
          "link":"http://qrcode.divhart.com/",
          "linkFile": "https://divhart.com/project/divqr-application-pour-creer-un-qr-code/",
          "plateform":"Web/Mobile Application",
          "imgsurl":["images/portfolio/QrCode/présentation-app-qr-code.png"]
        },
        {
          "name":"Htechstore",
          "description":"E-commerce Website",
          "technologies": "Wordpress",
          "link":"",
          "plateform":"Website",
          "imgsurl":["images/portfolio/Htechstore/Présentation htechstore.png"]
        },
        {
          "name":"Divhart",
          "description":"Corporate website for DIVHART - programming and IT consulting",
          "technologies": "Wordpress",
          "link":"https://divhart.com",
          "plateform":"Website/Mobile App",
          "imgsurl":["images/portfolio/Divhart/Présentation divhart.png"]
        },
        {
          "name":"Yotev",
          "description":"Online TV - E-commerce for digital products",
          "technologies": "Wordpress",
          "link":"",
          "plateform":"Website",
          "imgsurl":["images/portfolio/Yotev/présentation_yotev.png"]
        },
        {
          "name":"Acosports",
          "description":"Sharing sports programs and the experience of coaches",
          "technologies": "ReactJs/Spring",
          "link":"",
          "plateform":"Web Application",
          "linkFile":"https://divhart.com/project/project019/",
          "imgsurl":["images/portfolio/Acosport/acosport.jpg"]
        },
        {
          "name":"Forum de cardiologie",
          "description":"Association working for the promotion and development of cardiology",
          "technologies": "Wordpress",
          "link":"",
          "plateform":"Website",
          "imgsurl":["images/portfolio/Forum website/1.png"]
        },
        {
          "name":"Portfolio",
          "description":"Some examples from my project portfolio",
          "technologies": "ReactJs",
          "link":"https://portfolio.divhart.com/",
          "plateform":"Web Application",
          "linkFile":"",
          "imgsurl":["images/portfolio/présentation portfolio.png"]
        }
      ],
      [
        // Design
        {
          "name":"Santé-IT",
          "description":"Creation of logo and brand color palette",
          "technologies": "Canva",
          "link":"",
          "plateform":"Logo / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Santé-it/design.png"]
        },
        {
          "name":"Dbatel",
          "description":"Creation of logo and brand color palette",
          "technologies": "Canva",
          "link":"",
          "plateform":"Logo / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Dbatel/design.gif"]
        },
        {
          "name":"Virtual hotel",
          "description":"Creation of logo and brand color palette",
          "technologies": "Canva",
          "link":"",
          "plateform":"Logo / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Virtual hotel/design.png"]
        },
        {
          "name":"Divhart",
          "description":"Creation of logo and brand color palette",
          "technologies": "Canva",
          "link":"",
          "plateform":"Logo / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Divhart/design.gif"]
        },
        {
          "name":"Medeva",
          "description":"Creation of logo and brand color palette",
          "technologies": "Canva",
          "link":"",
          "plateform":"Logo / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Medeva/design.gif"]
        },
        {
          "name":"Yotev",
          "description":"Creation of logo and brand color palette",
          "technologies": "Canva",
          "link":"",
          "plateform":"Logo / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Yotev/design.png"]
        }
      ],
      [
        // Business card
        {
          "name":"Divhart",
          "description":"Creation of business card",
          "technologies": "Canva",
          "link":"",
          "plateform":"Business card / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Divhart/Business card/card1.png", "images/portfolio/Divhart/Business card/card2.png"]
        },
        {
          "name":"Medeva",
          "description":"Creation of business card",
          "technologies": "Canva",
          "link":"",
          "plateform":"Business card / Design",
          "linkFile":"",
          "imgsurl":["images/portfolio/Medeva/Business card/card1.png", "images/portfolio/Medeva/Business card/card2.png"]
        }
      ]
      
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ],
    "certificats":[
      {
        "name": "Advanced React",
        "company":"Meta | Coursera",
        "logo": "",
        "imgPath": "images/meta.png",
        "date": "December 2022",
        "class": "metaLogo"
      },
      {
        "name": "Principles of UX/UI Design",
        "company":"Meta | Coursera",
        "logo": "",
        "imgPath": "images/meta.png",
        "date": "December 2022",
        "class": "metaLogo"
      },
      {
        "name": "Programming with JavaScript",
        "company":"Meta | Coursera",
        "logo": "",
        "imgPath": "images/meta.png",
        "date": "November 2022",
        "class": "metaLogo"
      },
      {
        "name": "React Basics",
        "company":"Meta | Coursera",
        "logo": "",
        "imgPath": "images/meta.png",
        "date": "January 2023",
        "class": "metaLogo"
      },
      {
        "name": "HTML and CSS in depth",
        "company":"Meta | Coursera",
        "logo": "",
        "imgPath": "images/meta.png",
        "date": "January 2023",
        "class": "metaLogo"
      },
      {
        "name": "Version Control",
        "company":"Meta | Coursera",
        "logo": "",
        "imgPath": "images/meta.png",
        "date": "January 2023",
        "class": "metaLogo"
      },
      {
        "name":"Agile Project Management",
        "company":"Google | Coursera",
        "logo": "fa-google",
        "date": "November 2022",
      },
      {
        "name":"Google analytics individual qualification",
        "company":"Google digital academy",
        "logo": "fa-google",
        "date": "April 2023",
      },
      {
        "name":"Google Analytics",
        "company":"Google digital academy",
        "logo": "fa-google",
        "date": "November 2022",
      },
      {
        "name":"Agile with Atlassian Jira",
        "company":"Atlassian | Coursera",
        "logo": "",
        "imgPath":"images/atlassian.png",
        "date": "July 2022",
      },
      {
        "name":"the Fundamentals of digital marketing",
        "company":"Google Digital Garage",
        "logo": "fa-google",
        "date": "June 2022",
      },
      {
        "name":"AWS Cloud Practitioner Essentials",
        "company":"Aws",
        "logo": "",
        "imgPath":"images/aws.png",
        "date": "June 2022",
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData