import * as React from 'react';
import { useEffect } from 'react';
import { Component, useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';

const Portfolio = ({resumeData}) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [isDevPortfolioPair, setIsDevPortfolioPair] = useState(true);

    const openImageViewer = useCallback((index, table) => {
      setImages(resumeData.portfolio[table][index].imgsurl);
      setCurrentImage(0);
      setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    const checkIfPair= (number) =>{
      return number%2 === 0
    }

    useEffect( () => {
      if(resumeData.portfolio[0] && resumeData.portfolio[0].length > 0){
        let checkPortPair= checkIfPair(resumeData.portfolio[0].length);
        setIsDevPortfolioPair(checkPortPair);
      }
  }, [] ) 


    return (
      <>
      {isViewerOpen && (
    <ImageViewer
      src={images}
      currentIndex={currentImage}
      disableScroll={false}
      closeOnClickOutside={true}
      onClose={closeImageViewer}
      backgroundStyle={{
        backgroundColor: "rgba(0,0,0,0.9)"
      }}
    />
  )}
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Check Out Some of My Works</h1>
          <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
          <h1>Development</h1>
          <div className={isDevPortfolioPair ? "portfolioPair": "portfolioImpaire"}>
          {
            resumeData.portfolio && resumeData.portfolio[0] && resumeData.portfolio[0].map((item, index)=>{
              return(
                <div className="columns portfolio-item">
                  <div className="item-wrap">
                      <img src={item.imgsurl[0]} 
                      className="item-img"/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5 className='title'>{item.name}</h5>
                          <h5 className='subTitle'>{item.technologies}</h5>
                          <p className='description'>{item.description}</p>
                          <p className='plateform'>{item.plateform}</p>
                          <ul className='linkPortfolio'>
                            {item.link && 
                              <li key={item.link}>
                                <a href={item.link}><i className="fa fa-chrome"></i></a>
                              </li>
                            }
                            {item.linkFile && 
                              <li key={item.linkFile}>
                                <a href={item.linkFile}><i className="fa fa-file"></i></a>
                              </li>
                            }
                            <li key={index}>
                              <a onClick={ () => openImageViewer(index, 0) }><i className="fa fa-expand"></i></a>
                            </li> 
                            
                             
                          </ul>
                        </div>
                      </div>
                  </div>
                </div>
              )
            })
          }
          </div>
          

          <h1>Design</h1>
          {
            resumeData.portfolio && resumeData.portfolio[1] && resumeData.portfolio[1].map((item, index)=>{
              return(
                <div className="columns portfolio-item">
                  <div className="item-wrap">
                      <img src={item.imgsurl[0]} 
                      className="item-img"/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5 className='title'>{item.name}</h5>
                          <h5 className='subTitle'>{item.technologies}</h5>
                          <p className='description'>{item.description}</p>
                          <p className='plateform'>{item.plateform}</p>
                          <ul className='linkPortfolio'>
                            {item.link && 
                              <li key={item.link}>
                                <a href={item.link}><i className="fa fa-chrome"></i></a>
                              </li>
                            }
                            {item.linkFile && 
                              <li key={item.linkFile}>
                                <a href={item.linkFile}><i className="fa fa-file"></i></a>
                              </li>
                            }
                            <li key={index}>
                              <a onClick={ () => openImageViewer(index, 1) }><i className="fa fa-expand"></i></a>
                            </li> 
                            
                             
                          </ul>
                        </div>
                      </div>
                  </div>
                </div>
              )
            })
          }

           <h1>Business card</h1>
          {
            resumeData.portfolio && resumeData.portfolio[2] && resumeData.portfolio[2].map((item, index)=>{
              return(
                <div className="columns portfolio-item">
                  <div className="item-wrap">
                      <img src={item.imgsurl[0]} 
                      className="item-img"/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5 className='title'>{item.name}</h5>
                          <h5 className='subTitle'>{item.technologies}</h5>
                          <p className='description'>{item.description}</p>
                          <p className='plateform'>{item.plateform}</p>
                          <ul className='linkPortfolio'>
                            {item.link && 
                              <li key={item.link}>
                                <a href={item.link}><i className="fa fa-chrome"></i></a>
                              </li>
                            }
                            {item.linkFile && 
                              <li key={item.linkFile}>
                                <a href={item.linkFile}><i className="fa fa-file"></i></a>
                              </li>
                            }
                            <li key={index}>
                              <a onClick={ () => openImageViewer(index, 2) }><i className="fa fa-expand"></i></a>
                            </li> 
                            
                             
                          </ul>
                        </div>
                      </div>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
  </section>
  
  </>
        );
  }

export default Portfolio;